<template>
  <div>
    <!--    导航盒子-->
    <nav-box></nav-box>
    <el-card class="box-card">
      <!-- logo部分 -->
      <div>
        <img src="" alt=""/>
        <span>欢迎注册!</span>
      </div>

      <!-- 步骤条 -->
      <el-steps :active="activeIndex" finish-status="success" align-center>
        <el-step title="注册用户"></el-step>
        <el-step title="填写信息"></el-step>
        <el-step title="提交完成"></el-step>
      </el-steps>

      <!-- 用户表单 -->
      <el-form
        ref="addFormRef"
        :model="loginForm"
        :rules="rules"
        label-width="110px"
      >
        <el-row class="form-box">
          <el-col :span="12">
            <el-form-item label="登录手机号" prop="username">
              <el-input v-model="loginForm.username" placeholder="请输入登录账号">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="登录人姓名" prop="name">
              <el-input v-model="loginForm.name" placeholder="请输入联系人姓名"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="登录密码" prop="password">
              <el-input v-model="loginForm.password" placeholder="请输入密码" type="password">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="再次输入密码" prop="email">
              <el-input v-model="loginForm.email" placeholder="请输入密码" type="password"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="loginForm.sex" placeholder="请选择性别">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="code" class="verify" label="验证码">
              <el-input
                v-model="loginForm.code"
                placeholder="请输入验证码"
              ></el-input>
              <span class="pic"
              ><img :src="this.picCode" alt="" @click="variation"
              /></span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="短信验证码">
              <el-input
                v-model="loginForm.note"
                placeholder="请输入短信验证码"
                class="note"
              />
              <el-button @click="getNote" class="note-box" :disabled="disable" :class="{ codeGeting:isGeting }">
                {{ getCode }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button class="btn-box" @click="submitLogin">下 一 步</el-button>
      </el-form>

    </el-card>
    <qq-footer></qq-footer>
  </div>
</template>
<script>
import NavBox from '@/components/common/navBox'
import QqFooter from '@/components/common/qqFooter'
// import Cookies from 'js-cookie'

export default {
  components: { QqFooter, NavBox },
  data () {
    return {
      getCode: '获取验证码',
      isGeting: false,
      count: 120,
      disable: false,
      activeIndex: 0,
      loginForm: { enumType: 'SUPPLIER' },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '请输入合法的手机号',
            trigger: 'blur'
          }
        ],
        password: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        phone: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
          {
            min: 11,
            max: 11,
            message: '请输入合法的手机号',
            trigger: 'blur'
          }
        ],
        email: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        note: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }]
      },
      picCode: '',
      // 性别状态
      options: [
        {
          value: 'MAN',
          label: '男'
        },
        {
          value: 'WOMAN',
          label: '女'
        }
      ],

      // 用户类型
      typeOptions: [
        {
          typeValue: 'INSIDE',
          label: 'INSIDE'
        }

      ],
      typeValue: [],
      value: []
    }
  },
  created () {
    this.getClod()
  },
  mounted () {
  },
  methods: {
    // 获取验证码
    async getClod () {
      const sn = new Date().getTime()
      sessionStorage.setItem('sn', sn)
      this.picCode = await `${this.getaway.redirect}srm/genVerificationCode?from=WEB&sn=${sn}`
    },
    variation () {
      this.picCode = this.picCode + '&k=' + Math.random()
    },

    // 获取短信验证码
    getNote () {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          this.getVerifyCode()
          const param = {
            code: this.loginForm.code,
            phone: this.loginForm.username
          }
          this.axios.post(`${this.getaway.redirect}srm/getVerifyCode`, param).then(res => {
            if (res.code === '0') {
              this.$message({
                message: '发送成功',
                type: 'success'
              })
            } else {
              this.variation()
            }
          })
        } else {
          return false
        }
      })
    },

    // 提交表单信息
    async submitLogin () {
      const next = {
        address: this.loginForm.address,
        name: this.loginForm.name,
        password: this.loginForm.password,
        phone: this.loginForm.username,
        sex: this.loginForm.sex,
        username: this.loginForm.username,
        enumType: this.loginForm.enumType,
        roleIds: '1308674400493449218'
      }
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          await this.axios
            .post(`${this.getaway.redirect}srm/suppliers/registerAccount?code=${this.loginForm.note}`, next)
            .then((res) => {
              if (res.code === '0') {
                sessionStorage.setItem('usreId', res.data.id)
                this.$router.push('/mation')
              }
            })
        } else {
          return false
        }
      })
    },

    quit () {
      this.$router.push('/index')
    },

    getVerifyCode () {
      var countDown = setInterval(() => {
        if (this.count < 1) {
          this.isGeting = false
          this.disable = false
          this.getCode = '获取验证码'
          this.count = 120
          clearInterval(countDown)
        } else {
          this.isGeting = true
          this.disable = true
          this.getCode = this.count-- + 's后重发'
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.codeGeting {
  background: #cdcdcd;
  border-color: #cdcdcd;
}

/deep/ .el-step__head.is-process {
  color: #0E1C60;
}

/deep/ .el-step__title.is-process {
  color: #0E1C60;
}

.el-select {
  width: 100%;
}

.box {
  width: 1200px;
  margin: 20px auto;
  box-sizing: border-box;
  text-align: right;
  color: #ccc;

  > i {
    cursor: pointer;
  }
}

.el-card {
  width: 80%;
  margin: 0 auto;
}

.el-steps {
  width: 80%;
  margin: 20px auto;
}

.form-box {
  width: 80%;
  margin: 0 auto;
}

.note {
  width: 55%;
  margin-right: 20px;
}

.el-button {
  border-radius: 4px;

  &:hover {
    background-color: #fff;
    color: #3150e9;
    border: 1px solid transparent;
    box-shadow: 0px 0px 10px 4px rgba(25, 54, 196, 0.1);
  }
}

.btn-box {
  border-radius: 20px;
  padding: 6px 20px;
}

/deep/ .verify {
  position: relative;

  .el-input__inner {
    width: 55%;
  }

  .pic {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 92%;
    border: 1px solid #dcdfe6;

    > img {
      width: 100%;
    }
  }
}

.btn-box {
  display: block;
  margin: 20px auto;
}

.note-box {
  width: 30%;
  padding: 12px 0;
}
</style>
