<template>
<div>
  <div class="footer w">
    <p>沃莱迪金属材料有限公司All rights reserved</p>
    <!--<p>蜀ICP备19039541号-3</p>-->
    <a href="https://beian.miit.gov.cn" style="text-decoration: none"><p>蜀ICP备19039541号-3</p></a>
  </div>
</div>
</template>

<script>
export default {
  name: 'qqFooter',
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.footer {
  margin-top: 38px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  p {
    text-align: center;
    font-size: 12px;
    color: #333333;
  }
}
</style>
